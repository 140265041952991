import React from "react"

import UtilityBox from "../../components/UtilityBox"
import {
  PageTitleContainer,
  UtilityBoxContainer,
} from "../../components/layout/Containers"
import { H1, H2 } from "../../components/elements/Headings"
import SEO from "../../components/seo"
import Layout from "../../components/layout/Layout"

const Base64Page = () => {
  return (
    <>
      <SEO title="Base64 utilities" />
      <Layout>
        <PageTitleContainer>
          <H1>Base64 Utilities</H1>
          <H2>All the utilities available</H2>
        </PageTitleContainer>
        <UtilityBoxContainer>
          <UtilityBox
            key={"base64Encode"}
            path="/base64/encode"
            name="BASE64 Encode"
            description="Encode whatever String in Base64 format"
          />
          <UtilityBox
            key={"base64Decode"}
            path="/base64/decode"
            name="BASE64 Decode"
            description="Decode whatever Base64 in String format"
          />
        </UtilityBoxContainer>
      </Layout>
    </>
  )
}

export default Base64Page
